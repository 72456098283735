@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap");

body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

tspan {
  color: green !important;
}
